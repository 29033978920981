import type { FormElementComponentClassDefinition, ValidatorClass, PluginClass, FilterClass } from '../models';

class FormService {
  private _elementMap = new Map<string, FormElementComponentClassDefinition>();
  private _filterMap = new Map<string, FilterClass>();
  private _validatorMap = new Map<string, ValidatorClass>();
  private _pluginMap = new Map<string, PluginClass>();

  public registerFormElement = (name: string, definition: FormElementComponentClassDefinition) => {
    if (this._elementMap.has(name)) {
      console.warn(`[Form service] Form element "${name}" is already registered and will be overwritten`);
    }

    this._elementMap.set(name, definition);
  };

  public getFormElement = (name: string) => {
    if (!this._elementMap.has(name)) {
      console.warn(`[Form service] Form element "${name}" is not registered`);
    }

    return this._elementMap.get(name) || null;
  };

  public registerFilter = (name: string, definition: FilterClass) => {
    if (this._filterMap.has(name)) {
      console.warn(`[Form service] Form filter "${name}" is already registered and will be overwritten`);
    }

    this._filterMap.set(name, definition);
  };

  public getFilterInstance = (name: string) => {
    if (this._filterMap.has(name)) {
      const filterClass = this._filterMap.get(name) as FilterClass;
      return new filterClass();
    }

    console.warn(`[Form service] Form filter "${name}" is not registered`);
    return null;
  };

  public registerValidator = (name: string, definition: ValidatorClass) => {
    if (this._validatorMap.has(name)) {
      console.warn(`[Form service] Form validator "${name}" is already registered and will be overwritten`);
    }

    this._validatorMap.set(name, definition);
  };

  public getValidatorInstance = (name: string) => {
    if (this._validatorMap.has(name)) {
      const validatorClass = this._validatorMap.get(name) as ValidatorClass;
      return new validatorClass();
    }

    console.warn(`[Form service] Form validator "${name}" is not registered`);
    return null;
  };

  public registerPlugin = (name: string, definition: PluginClass) => {
    if (this._pluginMap.has(name)) {
      console.warn(`[Form service] Form plugin "${name}" is already registered and will be overwritten`);
    }

    this._pluginMap.set(name, definition);
  };

  public getPluginInstance = (name: string) => {
    if (this._pluginMap.has(name)) {
      const pluginClass = this._pluginMap.get(name) as PluginClass;
      return new pluginClass();
    }

    console.warn(`[Form service] Form plugin "${name}" is not registered`);
    return null;
  };
}

const formService = new FormService();

export { formService };
