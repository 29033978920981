<script lang="ts" setup>
import type { PropType } from 'vue';
import type { VCombobox } from 'vuetify/components';
import { useSelectableFormElement } from '../composables';
import { SelectableFormElement } from '../models';
import { CtsFieldMessage } from './';
import { ref, toRaw, toValue, watch } from 'vue';

const props = defineProps({
  element: {
    type: Object as PropType<SelectableFormElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  enabled,
  loading,
  readonly,
  value,
  fullName,
  messagesAsString,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  onInput,
  onFocus,
  onBlur,
  labelComponent,
  prependComponent,
  prependInnerComponent,
  appendComponent,
  appendInnerComponent,
  items,
  itemComponent,
  itemAppendComponent,
  itemPrependComponent,
  label,
  suffix,
  hint,
  color,
  bgColor,
  density,
  variant,
  maskValue
} = useSelectableFormElement(props.element);

const combobox = ref<VCombobox | null>(null);

const onClear = () => {
  onInput(new InputEvent('input'));
};

const onChipClose = () => {
  onInput(new InputEvent('input'));
  combobox.value?.focus();
};

const onUpdate = () => {
  onInput(new InputEvent('input'));
};

const onFocused = (focused: boolean) => {
  if (!focused) {
    setTimeout(() => {
      onBlur(new FocusEvent('blur'));
    }, 1);
  } else {
    onFocus(new FocusEvent('focus'));
  }
};

const onSearch = (val: any) => {
  if (combobox.value) {
    combobox.value.search = maskValue(val);
  }
};
</script>

<template>
  <v-combobox
    ref="combobox"
    v-bind="attributes"
    v-model="value"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }"
    :name="fullName"
    :label="label"
    :suffix="suffix"
    :hint="hint"
    :color="color"
    :bg-color="bgColor"
    :density="density"
    :variant="variant"
    :disabled="!enabled"
    :readonly="readonly"
    :messages="messagesAsString"
    :loading="loading"
    :items="items"
    @update:model-value="onUpdate"
    @update:search="onSearch"
    @update:focused="onFocused"
    @click:close="onClear"
    @click:clear="onClear">
    <template #message>
      <cts-field-message :element="element" />
    </template>
    <template #chip="{ item, props }">
      <v-chip
        v-bind="props"
        :closable="props.closable !== false && enabled && !readonly"
        @click:close="onChipClose">
        {{ item.title }}
      </v-chip>
    </template>
    <template
      v-if="labelComponent"
      #label="{ label, props }">
      <component
        :is="labelComponent"
        :label="label"
        :props="props"
        :element="element" />
    </template>
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template
      v-if="prependInnerComponent"
      #prepend-inner>
      <component
        :is="prependInnerComponent"
        :element="element" />
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
    <template
      v-if="appendInnerComponent"
      #append-inner>
      <component
        :is="appendInnerComponent"
        :element="element" />
    </template>
    <template
      v-if="itemComponent"
      #item="{ item, index, props }">
      <component
        :item="item"
        :index="index"
        :props="props"
        :is="itemComponent"
        :element="element" />
    </template>
    <template
      v-if="itemAppendComponent"
      #append-item>
      <component
        :is="itemAppendComponent"
        :element="element" />
    </template>

    <template
      v-if="itemPrependComponent"
      #prepend-item>
      <component
        :is="itemPrependComponent"
        :element="element" />
    </template>
  </v-combobox>
</template>
