<script lang="ts" setup>
import { toRefs } from 'vue';
import type { ConfirmModalProps } from '../';
import DialogModal from './DialogModal.vue';

const props = defineProps<ConfirmModalProps>();
const { config } = toRefs(props);

const onConfirm = (close: () => void) => {
  config.value.onConfirm?.call(this);
  close();
};

const onCancel = (close: () => void) => {
  config.value.onCancel?.call(this);
  close();
};
</script>

<template>
  <dialog-modal :config="config">
    <template #actions="{ close }">
      <div class="d-flex justify-space-around flex-row w-100">
        <v-btn
          class="text-capitalize"
          rounded="pill"
          color="secondary"
          variant="flat"
          @click="onConfirm(close)">
          {{ $t('modal.confirm') }}
        </v-btn>
        <v-btn
          class="text-capitalize"
          rounded="pill"
          color="secondary"
          variant="outlined"
          @click="onCancel(close)">
          {{ $t('modal.cancel') }}
        </v-btn>
      </div>
    </template>
  </dialog-modal>
</template>
