import { toRaw, toValue } from 'vue';
import { Permission } from '../enums';

export const checkPermission = (list: Permission[], included: Permission | Permission[]) => {
  const listToCheck = toRaw(toValue(list));
  const includedInCheck = toRaw(toValue(included));
  const includedList = Array.isArray(includedInCheck) ? includedInCheck : [includedInCheck];

  return !includedList.some((permission) => !listToCheck.includes(permission));
};
