import { defineStore } from 'pinia';
import { vuetifyBreakpointConfig } from '../../modules/vuetify/config/vuetify-breakpoint.config';
import { useBreakpoints } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';

export const useSidenavState = defineStore('sideNavState', () => {
  const drawer = ref(true);
  const rail = ref(false);
  const { mdAndDown } = useDisplay();

  const togglerIcon = computed(() => (rail.value ? 'mdi-arrow-right' : 'mdi-arrow-left'));

  onMounted(() => {
    const breakpoints = useBreakpoints(vuetifyBreakpointConfig);

    drawer.value = breakpoints.isGreater('md');
  });

  const toggleNavbar = () => {
    rail.value = false;
    drawer.value = !drawer.value;
  };
  const toggleMiniSidenav = () => {
    drawer.value = true;
    rail.value = !rail.value;
  };

  return {
    mdAndDown,
    drawer,
    rail,
    togglerIcon,
    toggleNavbar,
    toggleMiniSidenav
  };
});
