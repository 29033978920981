import { FormElement, type FormValidatorResult } from '../models';
import { all as mergeAll } from 'deepmerge';

export abstract class FormElementPlugin<TSettings extends FormPluginSettings = FormPluginSettings> {
  protected _name = '';
  protected _settings: TSettings = {} as TSettings;

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onInitialize(_element: FormElement<any>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onFocus(_element: FormElement<any>, _focused: boolean, _event: FocusEvent): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onInput(_element: FormElement<any>, _value: any, _event: InputEvent): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onEnable(_element: FormElement<any>, _enabled: boolean): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onVisible(_element: FormElement<any>, _visible: boolean): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onValidate(_element: FormElement<any>, _result: FormValidatorResult): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onValueChange(_element: FormElement<any>, _value: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onAddChild(_element: FormElement<any>, _newChild: FormElement<any>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onRemoveChild(_element: FormElement<any>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onHierarchyChange(_element: FormElement<any>): void {}

  public get name() {
    return this._name;
  }

  public set name(name) {
    this._name = name;
  }

  public get settings(): TSettings {
    return this._settings;
  }

  public set settings(settings: Partial<TSettings>) {
    this._settings = mergeAll([this._settings, settings]) as TSettings;
  }
}

export type FormPluginSettings = any;

export interface FormElementPluginConfig<TSettings extends FormPluginSettings = FormPluginSettings> {
  plugin: string;
  settings?: TSettings;
}

export type PluginClass = new () => FormElementPlugin;
