<script lang="ts" setup>
import type { PropType } from 'vue';
import { FormElement } from '../models';
import { useFormElement } from '../composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const { t } = useI18n();

const { message } = useFormElement(props.element);

const finalMessage = computed(() => {
  if (message.value) {
    return {
      message: message.value.settings.needTranslate
        ? t(message.value.message, message.value.settings.translatePlaceholders || {})
        : message.value.message,
      class: `cts-field-message cts-field-message--${message.value.type}`
    };
  }

  return null;
});
</script>

<template>
  <div
    v-if="finalMessage"
    :class="finalMessage.class">
    {{ finalMessage.message }}
  </div>
</template>
