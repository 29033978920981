import type { FormValidator, FormValidatorContext, FormValidatorMessage, FormValidatorResult } from './form-validator.model';

export class FormValidatorChain {
  protected _validators = new Map<string, FormValidator<any>>();
  protected _enabled = true;
  protected _valid: boolean | undefined = undefined;
  protected _messages: FormValidatorMessage[] = [];

  public validate = (value: any, context: FormValidatorContext): Promise<FormValidatorResult> => {
    return new Promise((resolve) => {
      const enabledValidators = Array.from(this._validators.values()).filter((validator) => validator.enabled);

      Promise.all(enabledValidators.map((validator) => validator.validate(value, context))).then((results) => {
        this.reset();
        this._valid = true;

        for (const result of results) {
          this._valid &&= result.valid;
          this._messages.push(...result.messages);
        }

        resolve({
          valid: !enabledValidators.length || this._valid,
          messages: this._messages
        });
      });
    });
  };

  public reset = () => {
    this._validators.forEach((validator) => validator.reset());
    this._valid = undefined;
    this._messages = [];
  };

  public addValidator = (validator: FormValidator<any>, alias?: string) => {
    const name = alias || validator.name;
    this._validators.set(name, validator);
  };

  public hasValidator = (name: string) => {
    return this._validators.has(name);
  };

  public getValidator = (name: string) => {
    return this._validators.get(name) || null;
  };

  public removeValidator = (name: string) => {
    if (this.hasValidator(name)) {
      this._validators.delete(name);
    }
  };

  public clearValidators = () => {
    this._validators.clear();
  };

  public get enabled() {
    return this._enabled;
  }

  public set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  public get valid() {
    return this._valid;
  }

  public set valid(valid) {
    this._valid = valid;
    this._validators.forEach((validator) => (validator.valid = valid));
  }

  public get messages() {
    return this._messages;
  }

  public set messages(messages) {
    this._messages = messages;
  }

  public get validatorsCount() {
    return this._validators.size;
  }
}
